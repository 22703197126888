@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 4px solid rgba(0, 109, 119, 1);
  border-top-color: rgba(16, 24, 40, 1);
  animation: spinner 0.6s linear infinite;
}

.users-container {
  margin: 0 auto;
  background-color: rgb(164, 163, 163);
  text-align: center;
  max-width: 500px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .user {
    max-width: 200px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    &:hover {
      scale: 1.1;
    }
    .username {
      color: blue;
    }
  }
}
.user-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  align-content: center;
  .content {
    display: flex;
    align-items: center;
    gap: 20px;
    .icon {
      color: blue;
      font-size: 20px;
    }
    .descr {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .main {
        font-size: 20px;
        display: flex;
        flex-direction: column;
        p {
          margin: 0;
        }
      }
      .secondary {
        font-size: 16px;
        color: rgb(255, 83, 83);
      }
    }
  }
}
